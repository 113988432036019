@import "../styles/colours";
@import "../styles/fontsAndTextStyles";
@import "../styles/margins";

* {
    box-sizing: border-box;
}
footer { 
    display: flex;
    align-items: center;
    border-top: solid $border_colour 1px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    margin-left: $left_margin_desktop;
    margin-right: $right_margin_desktop;
    margin-top: 20px;
    min-width: auto;
    padding-top: 10px; 
    
    .additional_credits {
        color: $secondary_text_colour;
        font-family: $main_font;
        font-size: $paragraph_font_size;    
    }

    .main_credits{
        color: $secondary_text_colour;
        font-family: $main_font;
        font-size: $subtitle_font_size;
    }
}

@media screen and (max-width:800px){
    footer{
        margin-left: 0;
        margin-right: 0; 
        justify-content: center;
    } 
}
