@import "../styles/colours";
@import "../styles/fontsAndTextStyles";
@import "../styles/margins";

* {
    font-family: $main_font;
}

.valid_form_box {
    align-items: center;
    background-color: $valid_box_colour;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    height: 200px;

    h2 {
        @include standardTitle;
        color: $primary_text_colour;
    }

    p {
        @include standardParagraph;
        font-weight: bold;
    }
}

.valid_icon_container {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $valid_icon_background;
}