@import "../styles/colours";
@import "../styles/fontsAndTextStyles";
@import "../styles/margins";

* {
    box-sizing: border-box;
}

nav {
    align-items: center;
    border-bottom: solid $border_colour 1px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: $left_margin_desktop;
    margin-right: $right_margin_desktop;
    margin-bottom: 20px;

}

.logo_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    img {
        height: 30px;
    }
    
    span {
        color: $primary_accent_colour;
        font-family: 'Caprasimo', cursive;
        font-size: 30px;
        font-weight: bold;
    }
}


.links_container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 350px;
    gap: 5px;

    .link{
        color: $primary_text_colour;
        font-family: $main-font;
        font-size: $paragraph_font_size;
        text-decoration: none;
        
    }

   .link_to_social_media {
    width: 30px;
   }    
}


@media screen and (max-width:800px) {
    .links_container {
        display: none;
    }
    
    nav{
       margin-left: 0;
       margin-right: 0; 
       justify-content: center;
    }

}