$primary_text_colour:rgb(68,68, 68);
$secondary_text_colour: rgb(180, 180, 184);
$tertiary_text_colour:rgb(255, 255, 255);

$primary_accent_colour: rgb(38, 102, 169);
$secondary_accent_colour: rgb(235, 245, 255);

$border_colour: rgb(231, 231, 231);
$input_field_colour: rgb(246, 247, 252);

$valid_box_colour: rgb(228, 242, 209);
$valid_icon_background: rgb(125, 191, 47);

$additional_background_colour:rgba(68,68, 68, 0.9);