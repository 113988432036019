@import "_colours";

$main_font: Verdana, sans-serif;
$paragraph_font_size: 12px; 
$subtitle_font_size: 16px;
$title_font_size: 20px;


@mixin darkButton {
        background-color: $primary_accent_colour;
        border-radius: 2px;
        border-style: none;
        color: $tertiary_text_colour;
        font-family: $main_font;
        font-size: $paragraph_font_size;
        font-weight: bold;
        padding: 10px 20px;
        text-decoration: none;
        width: 120px;
}

@mixin lightButton {
    background-color: $secondary_accent_colour;
    border-radius: 2px;
    border-style: none;
    color: $primary_accent_colour; 
    font-family: $main_font;
    font-size: $paragraph_font_size;
    font-weight: bold;
    padding: 10px 20px;
    text-decoration: none;
    width: 120px;
}

@mixin standardParagraph {
        font-family: $main_font;
        font-size: $paragraph_font_size;
        line-height: 2;
}

@mixin standardTitle { 
        font-family: $main_font;
        font-size: $title_font_size ;
        font-weight: normal;
}